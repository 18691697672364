exports.components = {
  "component---src-components-course-details-index-js": () => import("./../../../src/components/courseDetails/index.js" /* webpackChunkName: "component---src-components-course-details-index-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cart-index-js": () => import("./../../../src/pages/cart/index.js" /* webpackChunkName: "component---src-pages-cart-index-js" */),
  "component---src-pages-checkout-index-js": () => import("./../../../src/pages/checkout/index.js" /* webpackChunkName: "component---src-pages-checkout-index-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-course-video-[id]-js": () => import("./../../../src/pages/course-video/[id].js" /* webpackChunkName: "component---src-pages-course-video-[id]-js" */),
  "component---src-pages-courses-index-js": () => import("./../../../src/pages/courses/index.js" /* webpackChunkName: "component---src-pages-courses-index-js" */),
  "component---src-pages-forgot-password-js": () => import("./../../../src/pages/forgot-password.js" /* webpackChunkName: "component---src-pages-forgot-password-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-instructor-profile-[id]-js": () => import("./../../../src/pages/instructor-profile/[id].js" /* webpackChunkName: "component---src-pages-instructor-profile-[id]-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-order-finish-index-js": () => import("./../../../src/pages/order-finish/index.js" /* webpackChunkName: "component---src-pages-order-finish-index-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-registration-js": () => import("./../../../src/pages/registration.js" /* webpackChunkName: "component---src-pages-registration-js" */),
  "component---src-pages-student-profile-js": () => import("./../../../src/pages/student-profile.js" /* webpackChunkName: "component---src-pages-student-profile-js" */),
  "component---src-pages-tos-js": () => import("./../../../src/pages/tos.js" /* webpackChunkName: "component---src-pages-tos-js" */)
}

