import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASE_URL } from '../../../config';
import axiosWithAuth from '../../utils/axios';

// Define una función asincrónica que guardará el progreso en la API
export const saveProgress = createAsyncThunk('progress/saveProgress', async (payload, { getState, rejectWithValue }) => {
    try {
      // Accede al estado actual
      const state = getState();
      const { courseId, studentId, lessonId, videoUrl, progressSeconds, percentageCompleted } = state.progress;
  
      // Construye el payload con los datos del estado y los datos proporcionados
      const requestData = {
        courseId,
        studentId,
        lessonId,
        videoUrl,
        progressSeconds,
        percentageCompleted,
      };

      if(requestData.progressSeconds){
        // Realiza la solicitud POST para guardar el progreso en la API
        const response = await axiosWithAuth().post(BASE_URL + '/student-progress', requestData);
        return response.data; // Puedes retornar cualquier dato útil aquí si es necesario
      }

      return rejectWithValue("Nada que guardar"); // Manejo de errores con createAsyncThunk
      
    } catch (error) {
      return rejectWithValue(error.response.data); // Manejo de errores con createAsyncThunk
    }
  });

const progressSlice = createSlice({
  name: 'progress',
  initialState: {
    courseId: null,
    studentId: null,
    lessonId: null,
    videoUrl: null,
    progressSeconds: null,
    percentageCompleted: null,
  },
  reducers: {
    setProgressAction: (state, action) => {
      return action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(saveProgress.pending, (state) => {
        // Opcional: Puedes manejar acciones antes de que se complete la solicitud
      })
      .addCase(saveProgress.fulfilled, (state, action) => {
        // Opcional: Puedes manejar acciones después de que se complete la solicitud
      })
      .addCase(saveProgress.rejected, (state, action) => {
        // Opcional: Puedes manejar acciones en caso de que la solicitud falle
      });
  },
});

export const { setProgressAction } = progressSlice.actions;

export default progressSlice.reducer;
