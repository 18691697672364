import { createSlice } from '@reduxjs/toolkit'

const cartSlice = createSlice({
  name: 'cart',
  initialState: { products: [], total: 0, subtotal: 0, discount: 0, coupon: "" },
  reducers: {
    setAddProductAction: (state, action) => {
      let newTotal;
      let newSubtotal;
      newTotal = state.total + (parseFloat(action.payload.price)) * action.payload.cant
      newSubtotal = state.subtotal + (parseFloat(action.payload.price)) * action.payload.cant
        return{
            ...state,
            products: [...state.products, action.payload],
            total: newTotal,
            subtotal: newSubtotal,
        }
    },
    setAddDiscountAction: (state, action) => {
      let subTotal = parseFloat(state.subtotal);
      let discountPercentage = parseFloat(action.payload.discount);
      let discountAmount = subTotal * (discountPercentage / 100);
      let newTotal = subTotal - discountAmount;
        return{
            ...state,
            coupon: action.payload.coupon,
            discount: discountAmount,
            total: newTotal,
        }
    },
    setRemoveProductAction: (state, action) => {
      
      let itemToRemove= state.products.find(element => element.id == action.payload);
      let newProducts = state.products.filter(element=> itemToRemove.id !== element.id);
      let newTotal = state.total - (parseFloat(itemToRemove.price) * itemToRemove.cant);
      let newSubtotal = state.total - (parseFloat(itemToRemove.price) * itemToRemove.cant);
        return{
            ...state,
            products: newProducts,
            total: newTotal,
            subtotal: newSubtotal,
        }
    },
    setRemoveProductsAction: (state, action) => {
      const productsToRemove = action.payload; // Una lista de productos a eliminar
    
      // Filtrar los productos que no están en la lista de productos a eliminar
      const newProducts = state.products.filter((product) => !productsToRemove.some((itemToRemove) => itemToRemove.id === product.id));
    
      // Calcular el nuevo total y subtotal en función de los productos restantes
      let newTotal = 0;
      newProducts.forEach((product) => {
        newTotal += parseFloat(product.price) * product.cant;
      })
    
      return {
        ...state,
        products: newProducts,
        total: newTotal,
        subtotal: newTotal, // Puede que desees calcular el subtotal de manera diferente si es necesario
      };
    },
    setClearCartAction: (state, action) => {      
        return {
            products: [],
            total: 0,
            subtotal: 0,
          }
    }
  }
})

export const { setAddProductAction, setRemoveProductAction,setClearCartAction, setRemoveProductsAction, setAddDiscountAction } = cartSlice.actions

export default cartSlice.reducer
