import { configureStore } from '@reduxjs/toolkit';
import  cartSlice  from './cart/cartSlice';
import authSlice from './auth/authSlice';
import progressSlice from './progress/progressSlice';


export const store = configureStore({
  reducer: {
    cart:cartSlice,
    auth:authSlice,
    progress: progressSlice
  },
})