import "bootstrap/dist/js/bootstrap.min.js";
import "@popperjs/core/dist/umd/popper.min.js";
import React from 'react'
import { Provider } from 'react-redux'
import { store } from './src/redux/store'

export const wrapRootElement = ({ element }) => {
    return (
      <Provider store={store}>
        {element}
      </Provider>
    )
}

export const onClientEntry = async () => {
  const userLanguage = navigator.language.split('-')[0];
  window.localStorage.setItem('gatsby-i18next-language', userLanguage);
};