import React from 'react';
import axios from 'axios';
import { BASE_URL } from '../../config';
// Configura una instancia de Axios con la cabecera de autorización
const axiosWithAuth = () => {
    
  const token = JSON.parse(localStorage.getItem('authToken')); // Puedes obtener el token de tu almacenamiento local o de donde lo tengas
  const instance = axios.create({
    
    baseURL: BASE_URL, // URL base de tu API
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token.jwtToken}`, // Agrega el token de autorización a la cabecera
    },
  });

  // Interceptor para manejar errores de autorización
  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response && error.response.status === 401) {
        // Manejar aquí la lógica para redireccionar a la página de inicio de sesión o renovar el token si es necesario
      }
      return Promise.reject(error);
    }
  );

  return instance;
};

export default axiosWithAuth;