import { createSlice } from '@reduxjs/toolkit'
const authSlice = createSlice({
  name: 'auth',
  initialState: { isAuth: false, jwtToken: null, userData: {} },
  reducers: {
    setLoginAction: (state, action) => {
        return {
          ...state,
          isAuth : true,
          jwtToken: action.payload.jwtToken,
          userData: action.payload.userData
        }
    },
    setTokenAction: (state, action) => {
      return {
        isAuth : true,
        jwtToken: action.payload
      }
    },
    setLogoutAction: (state, action) => {
      return {
        isAuth : false,
        jwtToken: null
      }
    }
  }
})

export const { setLoginAction, setTokenAction, setLogoutAction } = authSlice.actions

export default authSlice.reducer
